import { Button, Table, Space, Select, Divider, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EmailLogs } from '@/interfaces/emailLogs.interface';
import { parseFilter } from '@/utils/filter';
import { useTranslation } from 'react-i18next';

export interface EmailLogsTableProps {
  logs?: EmailLogs[];
  total?: number;
  current?: number;
  loading?: boolean;
  onFilter?: (value: any) => any;
}

const EmailLogsTable = ({ logs, total, loading, onFilter }: EmailLogsTableProps) => {
  const { t } = useTranslation();

  const EmailLogsColumns: ColumnsType<EmailLogs> = [
    {
      title: t('emailsLogs.messageId'),
      dataIndex: 'message_id',
      key: 'message_id',
      render: (message_id: string) =>
        message_id &&
        message_id.substring(0, message_id.length > 20 ? 20 : message_id.length),
    },
    {
      title: t('emailsLogs.referenceId'),
      dataIndex: 'reference_id',
      key: 'reference_id',
    },
    {
      title: t('emailsLogs.referenceType'),
      dataIndex: 'reference_type',
      key: 'reference_type',
    },
    {
      title: t('emailsLogs.to'),
      dataIndex: 'to',
      key: 'to',
      render: (to: string) => to && to.split(',').join('\n'),
    },
    {
      title: t('emailsLogs.subject'),
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) =>
        created_at && new Date(created_at).toLocaleDateString(),
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      filters: [
        {
          text: t('emailsLogs.sent'),
          value: '1',
        },
        {
          text: t('general.error'),
          value: '0',
        },
      ],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
        <div style={{ padding: 8 }}>
          <Select
            value={selectedKeys[0]}
            style={{ width: '100%' }}
            defaultValue="1"
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
          >
            <Select.Option value="1">{t('emailsLogs.sent')}</Select.Option>
            <Select.Option value="0">{t('general.error')}</Select.Option>
          </Select>
          <Divider />
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
            >
              {t('general.filter')}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              {t('general.close')}
            </Button>
          </Space>
        </div>
      ),
      key: 'status',
      render: (status: number) =>
        status === 1 ? t('emailsLogs.sent') : t('general.error'),
    },
  ];

  const handleOnChange = (pagination: any = {}, filters: any = {}, sorter: any = {}) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...parseFilter(filters, value, sorter),
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <Table
      className="custom-table"
      dataSource={logs}
      columns={EmailLogsColumns}
      loading={loading}
      onChange={handleOnChange}
      rowKey={(e) => e._id}
      pagination={{
        total,
        defaultPageSize: 10,
        showTotal: (total) => t('general.total', { total }),
      }}
    />
  );
};

export default EmailLogsTable;
