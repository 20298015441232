import { Breadcrumb, Card, Col, Divider, Row } from 'antd';
import { HomeOutlined, MailOutlined } from '@ant-design/icons';
import EmailLogsTable from '@/components/emailLogs/EmailLogsTable';
// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// API
import APIService from '@/services/API';

const EmailLogsScreen = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    $skip: '0',
    $limit: '10',
  });
  const { isLoading, data } = useQuery(['email-logs', params], () =>
    APIService.getEmailLogs(params),
  );
  return (
    <div className="dashboard-screen fadeIn">
      <Card>
        <Row>
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <MailOutlined />
                      <span>{t('emailsLogs.title')}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
        <Divider />
        <EmailLogsTable
          current={(data?.skip || 0) / (data?.limit || 1)}
          loading={isLoading}
          logs={data?.data}
          onFilter={setParams}
          total={data?.total}
        />
      </Card>
    </div>
  );
};

export default EmailLogsScreen;
